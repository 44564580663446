<template>
  <div class="fixed h-[100dvh] z-[50]">
    <button @click="emit('toggle-sidebar')"
      :class="`absolute transition-all duration-300 delay-300 ${isOpenSidebar ? 'left-[60px]' : 'left-[85px]'} ${bannerIsVisible ? 'top-[58px]' : 'top-[27px]'}`">
      <Icon name="heroicons:chevron-right"
        class="text-gray-600 ml-auto -mr-1 h-6 w-6 p-1 bg-wobby-white border border-gray-200 rounded hover:bg-gray-100 hover:text-gray-900"
        aria-hidden="true" />
    </button>
    <div
      :class="`relative h-[100dvh] border-b  border-r border-gray-200 bg-wobby-white overflow-hidden transition-all ${isOpenSidebar ? 'w-[200px] lg:w-[250px] duration-300' : 'w-[88px] duration-500'}`">
      <div
        :class="`flex flex-col flex-shrink-0 items-stretch w-[200px] lg:w-[250px] h-[100dvh] pb-4 ${bannerIsVisible ? 'pt-10' : 'pt-2'}`">
        <!-- Logo -->
        <NuxtLink to="/"
          class="h-16 flex-shrink-0 flex items-center border-b border-gray-200 px-4 gap-x-4 mb-2 min-w-0 !border-transparent">
          <div class="flex items-center justify-between flex-1 gap-x-1.5 min-w-0">
            <div class="flex items-stretch justify-between gap-1.5 min-w-0 w-full flex-1">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 976.12 425.2"
                  class="absolute w-[147px] h-[64px] -ml-1" id="TEMPORARY_WOBBY_LOGO">
                  <path fill="#7547ff"
                    d="m316.95,219.46v-10.56c0-1.81-1.46-3.27-3.27-3.27h-59.15l54.67-22.64c1.67-.69,2.46-2.6,1.77-4.27l-4.05-9.77c-.69-1.67-2.6-2.46-4.27-1.77l-54.69,22.65,41.85-41.85c1.28-1.28,1.28-3.35,0-4.63l-7.46-7.46c-1.28-1.27-3.35-1.27-4.62,0l-41.85,41.85,22.65-54.67c.69-1.67-.1-3.58-1.77-4.27l-9.77-4.05c-1.67-.69-3.58.1-4.27,1.77l-22.65,54.67v-59.18c0-1.8-1.46-3.27-3.27-3.27h-10.56c-1.8,0-3.26,1.47-3.26,3.27v93.61h-93.61c-1.81,0-3.27,1.46-3.27,3.27v10.56c0,1.81,1.46,3.27,3.27,3.27h93.61v93.61c0,1.81,1.46,3.27,3.26,3.27h10.56c1.81,0,3.27-1.46,3.27-3.27v-59.16l22.65,54.68c.69,1.67,2.6,2.46,4.27,1.77l9.77-4.05c1.67-.69,2.46-2.6,1.77-4.27l-22.65-54.68,41.85,41.85c1.27,1.27,3.34,1.27,4.62,0l7.46-7.46c1.28-1.28,1.28-3.35,0-4.63l-41.86-41.86,54.69,22.66c1.67.69,3.58-.1,4.27-1.77l4.05-9.77c.69-1.67-.1-3.58-1.77-4.27l-54.7-22.65h59.18c1.81,0,3.27-1.46,3.27-3.27" />
                </svg>
                <LottieBox ref="icon" :size="{ width: 147, height: 64 }" :hasParentTriggers="true" :src='logo'
                  class="-ml-1" />
              </div>

              <button @click="emit('toggle-sidebar')">
                <Icon name="heroicons:chevron-left"
                  class="text-gray-600 ml-auto -mr-1 h-6 w-6 p-1 rounded hover:bg-gray-100 hover:text-gray-900"
                  aria-hidden="true" />
              </button>
            </div>
          </div>
        </NuxtLink>

        <!-- Search -->
        <div class="relative w-full px-4 mb-3">
          <UTooltip :prevent="isOpenSidebar" text="Search..." :shortcuts="[metaKeySymbol, 'K']"
            :popper="{ placement: 'right' }">
            <button @click="openCommandPalette"
              class="relative w-14 h-8 flex items-center justify-center text-gray-400 hover:bg-wobby-purple-50 hover:text-wobby-purple-500 rounded-md cursor-pointer transition-all duration-300"
              :class="isOpenSidebar ? 'opacity-0' : 'opacity-100'">
              <Icon name="heroicons:magnifying-glass" class="w-5 h-5" aria-hidden="true" />
            </button>
          </UTooltip>


          <UButton icon="i-heroicons-magnifying-glass" size="sm" color="white" variant="solid" :trailing="false"
            class="group flex-1 transition-all duration-300 absolute w-[calc(100%-2rem)] text-gray-500"
            :class="isOpenSidebar ? 'left-4 opacity-100' : 'left-6 opacity-0 pointer-events-none'"
            @click="openCommandPalette">
            <span class="font-normal">Search...</span>
            <div class="ml-auto flex gap-1 group-hover:opacity-100 opacity-0 transition-all duration-300">
              <UKbd :value="metaKeySymbol" />
              <UKbd value="K" />
            </div>
          </UButton>
        </div>

        <!-- Navigation -->
        <div
          :class="`flex-1 px-4 flex flex-col overflow-hidden transition-all duration-300 ${isOpenSidebar ? 'w-[200px] lg:w-[250px]' : 'w-[88px]'}`">
          <UVerticalNavigation :links="links" :ui="{
        base: 'mb-1',
        padding: 'px-2.5 py-2',
        active: 'text-wobby-purple-600 before:bg-wobby-purple-100',
        inactive: 'text-gray-500 hover:text-wobby-purple-500 hover:before:bg-wobby-purple-50',
        label: `transition-all duration-300 font-normal ${!isOpenSidebar && 'opacity-0'}`,
        badge: {
          base: 'bg-wobby-light-aquamarine group-hover:bg-wobby-light-aquamarine text-wobby-black rounded-md px-2 text-xs',
        }
      }">
            <template #icon="{ link, isActive }">
              <UTooltip :prevent="isOpenSidebar" :text="link.label" :shortcuts="link.shortcuts"
                :popper="{ placement: 'right' }">
                <Icon :name="link.icon" :class="`flex-shrink-0 transition-all duration-300 ${!isOpenSidebar && 'ml-[8px]'} w-[20px] h-[20px] ${isActive ? 'text-wobby-purple-600' : 'text-gray-400'} group-hover:text-wobby-purple-500`" />
              </UTooltip>
            </template>
          </UVerticalNavigation>

          <div class="flex flex-1"></div>

          <div v-if="identityStore.isUserInActiveTrial"
            class="mb-5 py-4 text-wobby-purple-400 rounded-lg flex justify-center items-center bg-wobby-purple-50"
            :class="isOpenSidebar ? 'mx-0.5 ring-wobby-white ring-opacity-25 ring' : ''">
            <div v-auto-animate v-if="!isOpenSidebar"
              class="flex justify-center items-center text-center text-sm flex-col w-full transition-all duration-200 ease-linear"
              :class="isOpenSidebar ? 'px-4' : ''">
              <Icon name="heroicons:heart" class="h-5 w-5 mb-3" aria-hidden="true" />

              <button type="button"
                class="px-3 py-1  text-gray-600 rounded-md w-full flex justify-center items-center transition-color duration-150">
                Trial
              </button>
            </div>
            <div v-auto-animate v-if="isOpenSidebar && identityStore.user"
              class="flex justify-center items-center text-center text-sm flex-col w-full transition-all duration-200 ease-linear"
              :class="isOpenSidebar ? 'px-4' : ''">
              <Icon name="heroicons:heart" class="h-5 w-5 mb-3" aria-hidden="true" />

              <div class="mb-3">
                <p class="font-fakt-medium text-xs transition-all duration-300 ease-linear" v-show="isOpenSidebar">
                  Loving the experience?<br />
                </p>
                <span class="text-wobby-purple-300 text-xs">Trial expires {{ trialsEndsInText }}</span>

              </div>
              <NuxtLink to="https://calendly.com/amra-wobby/30min" type="button" target="_blank"
                class="px-2 py-1.5 text-xs hover:bg-wobby-purple-400 bg-wobby-purple-200 hover:text-white text-wobby-purple-600 rounded-md w-full flex justify-center items-center transition-color duration-150">
                Let's chat!
              </NuxtLink>
            </div>

          </div>

          <UVerticalNavigation :links="bottomLinks" :ui="{
        base: 'mb-1',
        padding: 'px-2.5 py-2',
        active: 'text-wobby-purple-600 before:bg-wobby-purple-100',
        inactive: 'text-gray-500 hover:text-wobby-purple-500 hover:before:bg-wobby-purple-50',
        label: `transition-all duration-300 font-normal ${!isOpenSidebar && 'opacity-0'}`
      }">
            <template #icon="{ link, isActive }">
              <UTooltip :prevent="isOpenSidebar" :text="link.label" :shortcuts="link.shortcuts"
                :popper="{ placement: 'right' }">
                <Icon :name="link.icon"
                  :class="`flex-shrink-0 transition-all duration-300 ${!isOpenSidebar && 'ml-[8px]'} w-[20px] h-[20px] ${isActive ? 'text-wobby-purple-600' : 'text-gray-400'} group-hover:text-wobby-purple-500`"></Icon>
              </UTooltip>
            </template>
          </UVerticalNavigation>

          <UDivider class="mt-1 mb-2" />

          <!-- <div class="w-[168px] lg:w-[218px]"> -->
          <SidebarUserDropdown :isOpenSidebar />
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import logo from '@/assets/animated-icons/logo-purple.json?url';
import { prettyTrialEndsIn } from '~/utils';
const { metaSymbol } = useShortcuts()

const metaKeySymbol = computed(() => {
  const isMac = navigator?.platform?.toUpperCase().indexOf('MAC') >= 0;
  return isMac ? metaSymbol.value : 'Ctrl';
})

const appStore = useAppStore();
const identityStore = useIdentityStore();
const icon = ref<any | null>(null);

const props = defineProps({
  isOpenSidebar: { type: Boolean, required: true },
});

const emit = defineEmits(['toggle-sidebar']);

const bannerIsVisible = computed(() => {
  return appStore.banner.show;
})

const trialsEndsInText = computed(() => {
  if (!identityStore?.user?.trialEndsAt) return '';
  return prettyTrialEndsIn(identityStore.user?.trialEndsAt);
});

watch(() => props.isOpenSidebar, (value) => {
  if(!icon.value) return;
  if (value) {
    icon.value?.playAnimation();
  } else {
    icon.value?.reverseAnimation({ speed: 2.5 });
  }
}, { immediate: true });

const links = computed(() => {
  let visibleLinks = [
    {
      label: 'Home',
      to: '/',
      icon: 'i-heroicons-home',
      isActive: false,
      shortcuts: ['G', 'H'],
    },

  ];




  visibleLinks.push({
    label: 'Documents',
    to: '/documents',
    icon: 'i-heroicons-document-text',
    isActive: false,
    shortcuts: ['G', 'D'],
  });

  visibleLinks.push({
    label: 'Statistical Sources',
    to: '/discovery/search',
    icon: 'i-heroicons-viewfinder-circle',
    isActive: false,
    shortcuts: ['G', 'E']
  });

  visibleLinks.push({
    label: 'My Sources',
    to: '/knowledge',
    icon: 'i-heroicons-book-open',
    isActive: false,
    shortcuts: ['G', 'K']
  })

  if (identityStore.hasFeature('STAT_CONVERTER')) {
    visibleLinks.push({
      label: 'StatConverter',
      to: '/stat-converter',
      icon: 'i-heroicons-arrow-path',
      isActive: false,
      shortcuts: ['G', 'S']
    });
  }

  return visibleLinks;
})

const bottomLinks = computed(() => {
  return [
    {
      label: 'Invite members',
      click: () => {
        document.dispatchEvent(new CustomEvent('openInviteMembers'));
      },
      icon: 'i-heroicons-user-plus',
      isActive: false
    },
    {
      label: 'Help & support',
      click: () => {
        document.dispatchEvent(new CustomEvent('openHelpSupportSlideover'));
      },
      icon: 'i-heroicons-question-mark-circle',
      isActive: false
    }
  ];
})

const openCommandPalette = () => {
  document.dispatchEvent(new CustomEvent('openCommandPalette'));
}

// set active link
const route = useRoute()

onMounted(async () => {
  links.value.forEach((link) => {
    if (link.to === "/") {
      link.isActive = route.path === link.to
    } else {
      link.isActive = route.path.startsWith(link.to)
    }
  })

  if (icon.value) {
    const dotLottie = icon.value.getDotLottieInstance();
    dotLottie.addEventListener('load', () => {
      document.getElementById("TEMPORARY_WOBBY_LOGO")?.remove();
      if (props.isOpenSidebar) {
        icon.value?.playAnimation();
      }
    });
  }

})
</script>

<style></style>