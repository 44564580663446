<template>
    <div class="absolute top-0 text-center w-full z-[99]">
        <div class="mx-auto bg-wobby-light-aquamarine py-1 ring-1 ring-wobby-purple-500 ">
            <div class="flex justify-center items-center gap-2">
                <p class="font-fakt-medium text-sm text-gray-800">{{ banner.message }}
                </p>

                <div class="flex items-center gap-1" v-if="banner.link">
                    <a :href="banner.link" target="_blank"
                        class="inline-flex w-full justify-center rounded-md  cursor-pointer text-sm text-wobby-purple underline  sm:w-auto">{{ banner.linkText }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
const { banner } = storeToRefs(useAppStore())
</script>