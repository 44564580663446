<template>
  <UModal v-model="isOpen">
    <SearchSearchbar />
  </UModal>
</template>
  
<script lang="ts" setup>
import { DocumentType } from '~/types';

const router = useRouter()
const identityStore = useIdentityStore();
const isOpen = ref(false)

const openCommandPalette = () => {
  isOpen.value = true
}

const closeCommandPalette = () => {
  isOpen.value = false
}

watch(() => router.currentRoute.value, () => {
  isOpen.value = false
})

const loading = ref(false)
provide('loading', loading)

defineShortcuts({
  meta_k: {
    usingInput: true,
    handler: () => {
      isOpen.value = !isOpen.value
    }
  },
  'g-h': () => router.push('/'),
  'g-e': () => router.push('/discovery/search'),
  'g-d': () => router.push('/documents'),
  'g-k': () => router.push('/knowledge'),
  'g-s': () => identityStore.hasFeature('STAT_CONVERTER') && router.push('/stat-converter')
})

onMounted(() => {
  document.addEventListener('openCommandPalette', openCommandPalette);
  document.addEventListener('closeCommandPalette', closeCommandPalette);
})

onUnmounted(() => {
  document.removeEventListener('openCommandPalette', openCommandPalette);
  document.removeEventListener('closeCommandPalette', closeCommandPalette);
})

const { createDocument } = useDocumentService();
const loadingCreateDocument = ref(false);
const createNewDocument = async (type: DocumentType, name: string = 'New Document') => {
    try {
        loadingCreateDocument.value = true;
        const createdDocument = await createDocument({
          name,
          type,
        });

        location.href = `/documents/${createdDocument.id}`;
    } catch (error) {
        console.log(error);
    } finally {
        loadingCreateDocument.value = false;
    }
};
provide('loadingCreateDocument', loadingCreateDocument);
provide('createNewDocument', createNewDocument);
</script>