<template>
  <UPopover>
    <UButton
      :ui="{ color: { gray: { ghost: 'text-gray-500 dark:text-gray-400 hover:text-wobby-purple-400 hover:bg-wobby-purple-50 disabled:bg-transparent dark:hover:bg-gray-950 dark:disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-gray-500 dark:focus-visible:ring-gray-400' } } }"
      color="gray"
      variant="ghost"
      class="w-full"
    >
      <template #leading>
        <UAvatar
          :src="`/api/identity/users/${user.id}/avatar`"
          size="2xs"
          :class="`transition-all duration-300 ${!isOpenSidebar && 'ml-2'}`"
          v-if="user && user.id"
        />
      </template>

      <template #default>
        <span :class="`transition-all duration-300 ${!isOpenSidebar && 'opacity-0'}`">{{ firstNameWithFirstLetterCapitalized }}</span>
      </template>

      <template #trailing>
        <UIcon
          name="i-heroicons-ellipsis-vertical"
          class="w-5 h-5 ml-auto"
        />
      </template>
    </UButton>

    <template #panel>
      <div class="relative focus:outline-none overflow-y-auto scroll-py-1 divide-y divide-gray-200 dark:divide-gray-700 ring-1 ring-gray-200 dark:ring-gray-700 rounded-md shadow-lg bg-white dark:bg-gray-800">
        <div class="group flex items-center gap-2 w-full px-4 py-1.5 text-sm rounded-md text-gray-700 dark:text-gray-200 opacity-50 cursor-text select-text">
          <div class="text-left">
            <p>
              Signed in as
            </p>
            <p class="truncate font-medium text-gray-900 dark:text-white">
              {{ email }}
            </p>
          </div>
        </div>

        <div v-for="(items, i) in itemGroups" :key="`ìtem-${i}`" class="p-1">
          <UVerticalNavigation :links="items">
            <template #default="{ link }">
              <span class="relative flex items-center">
                <span class="block mr-4">{{ link.label }}</span>
                <span class="ml-auto">
                  <UKbd v-for="sKey in link.shortcuts">{{ sKey }}</UKbd>
                </span>
              </span>
            </template>
          </UVerticalNavigation>
        </div>
      </div>
    </template>
  </UPopover>
</template>

<script setup lang="ts">
const appStore = useAppStore();

const props = defineProps({
  isOpenSidebar: {
    type: Boolean,
    default: false
  }
})

const { metaSymbol } = useShortcuts()
const metaKeySymbol = computed(() => {
  const isMac = navigator?.platform?.toUpperCase().indexOf('MAC') >= 0;
  return isMac ? metaSymbol.value : 'Ctrl';
})

const itemGroups = computed(() => [[{
    label: 'Settings',
    icon: 'i-heroicons-cog-8-tooth',
    to: '/account/profile'
  }, {
    label: 'Command menu',
    icon: 'i-heroicons-command-line',
    shortcuts: [metaKeySymbol.value, 'K'],
    click: () => {
      document.dispatchEvent(new CustomEvent('openCommandPalette'));
    }
  }], [{
    label: 'Sign out',
    icon: 'i-heroicons-arrow-left-on-rectangle',
    click: () => {
      appStore.toggleDialog('signout', true)
    }
  }]
])

const identityStore = useIdentityStore();
const { user } = storeToRefs(identityStore);
const firstNameWithFirstLetterCapitalized = computed(() => {
  if (user.value) {
    return user.value.firstName.charAt(0).toUpperCase() + user.value.firstName.slice(1);
  }
  return '';
});
const email = computed(() => {
  if (user.value) {
    return user.value.email;
  }
  return '';
});
</script>

