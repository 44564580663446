<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="relative" style="z-index: 999 !important;" @close="closeModal">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-wobby-purple-100 bg-opacity-60 transition-opacity" />
            </TransitionChild>
            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-center justify-center p-4 sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative transform overflow-hidden w-full max-w-xl mx-auto">
                            <div class="bg-white px-8 py-6 rounded-lg shadow-xl">
                                <button type="button" @click="closeModal"
                                    class="absolute top-2 right-2 flex items-center p-2 hover:bg-gray-100 font-medium text-gray-500 hover:text-black rounded-full transition-colors text-left" 
                                    id="closeWelcome">
                                        <Icon name="heroicons:x-mark" class="w-4 h-4" />
                                </button>

                                <iframe class="w-full rounded-lg mt-3 mb-4" style="aspect-ratio: 16 / 9;"
                                    src="https://www.youtube.com/embed/_iD1vbIbg0Q"
                                    title="Getting Started with Wobby" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowfullscreen></iframe>

                                <div class="relative flex flex-col justify-between">
                                    <div class="text-wobby-purple-700 py-6">
                                        <h1 class="text-4xl font-fakt-medium mb-2">Welcome to Wobby, {{ capitalizedFirstName }} 👋</h1>
                                        <p class="text-lg">
                                            We're excited you're here. Let's&nbsp;explore&nbsp;Wobby&nbsp;together!
                                            <br>
                                            Start with a quick video and then create your first&nbsp;document!
                                        </p>
                                    </div>
                                </div>

                                <div class="flex flex-col items-center space-y-2">
                                    <a href="/documents"
                                        class="flex items-center w-full px-4 py-2 bg-white font-medium text-gray-600 hover:text-black border border-gray-300 rounded-md hover:bg-gray-50 transition-colors">
                                        <Icon name="heroicons:document" class="w-4 h-4 mr-2" />
                                        Create your first document
                                        <Icon name="heroicons:chevron-right" class="text-gray-400 w-3 h-3 ml-auto" />
                                    </a>
                                    <a href="https://help.wobby.ai/" target="_blank" 
                                        class="flex items-center w-full px-4 py-2 bg-white font-medium text-gray-600 hover:text-black border border-gray-300 rounded-md hover:bg-gray-50 transition-colors">
                                        <Icon name="heroicons:academic-cap" class="w-4 h-4 mr-2" />
                                        Visit the Documentation
                                        <Icon name="heroicons:chevron-right" class="text-gray-400 w-3 h-3 ml-auto" />
                                    </a>
                                    <a href="https://calendly.com/d/4wf-jmp-r8r/get-to-know-wobby" target="_blank"
                                        class="flex items-center w-full px-4 py-2 bg-wobby-purple-500 font-medium text-white border border-wobby-purple-500 rounded-md hover:bg-wobby-purple-400 transition-colors">
                                        <Icon name="heroicons:users" class="w-4 h-4 mr-2" />
                                        Book a Demo
                                        <Icon name="heroicons:chevron-right" class="text-gray-100 w-3 h-3 ml-auto" />
                                    </a>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
  
<script setup lang="ts">
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { computed, onMounted, ref } from 'vue'

const identityStore = useIdentityStore();

const props = defineProps<{
    show: boolean;
}>();

const router = useRouter();

// Define the emits
const emit = defineEmits(["close"]);

const closeModal = () => {
    emit('close');
};

const capitalizedFirstName = computed(() => {
    const firstName = identityStore.user?.firstName || '';
    return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
});
</script>



<style scoped>
.v-enter-active {
    transition: opacity 0.8s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}</style>