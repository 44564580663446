<template>
  <USlideover class="help-support-slideover z-[100]" v-model="isOpen">
    <div class="relative flex-1 flex flex-col w-full focus:outline-none w-screen max-w-md h-full bg-white dark:bg-gray-900">
        <div class="flex items-center justify-between gap-x-1.5 flex-shrink-0 border-b border-gray-200 dark:border-gray-800 h-[--header-height] p-4">
            <p class="text-gray-900 dark:text-white font-semibold flex items-center gap-x-1.5 min-w-0">
                Help & Support
            </p>
            <UButton color="gray" variant="ghost" @click="isOpen = false">
                <Icon name="heroicons-solid:x-mark" class="arrow-icon" />
            </UButton>
        </div>

        <div class="flex-1 overflow-y-auto p-4">
            <div class="flex flex-col gap-y-3">
                <UButton color="white" icon="i-heroicons-academic-cap" to="https://help.wobby.ai/" target="_blank">Documentation & Tutorials</UButton>
                <UButton color="white" icon="i-heroicons-envelope" to="https://wobby.ai/contact/" target="_blank">Contact</UButton>

                <h4 class="mt-3">Walkthrough video</h4>
                <iframe class="w-full rounded-lg mb-4" style="aspect-ratio: 16 / 9;"
                  src="https://www.youtube.com/embed/_iD1vbIbg0Q"
                  title="Getting Started with Wobby" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen></iframe>
            </div>
        </div>
    </div>
  </USlideover>
</template>

<script setup lang="ts">
const isOpen = ref(false);
const openHelpSupportSlideover = () => {
  isOpen.value = true;
}

onMounted(() => {
  document.addEventListener('openHelpSupportSlideover', openHelpSupportSlideover);
})

onUnmounted(() => {
  document.removeEventListener('openHelpSupportSlideover', openHelpSupportSlideover);
})
</script>